import React, { Component } from "react"
//import { Map } from "react-leaflet"
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet"

export default class Map extends Component {
  render() {
    const { options } = this.props

    if (typeof window !== "undefined") {
      return (
        <MapContainer
          center={[options.latitude, options.longitude]}
          zoom={18}
          style={{ height: "500px" }}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={[options.latitude, options.longitude]}>
            <Popup>
              <strong>{options.popup.title}</strong>
              <p>{options.popup.address}</p>
            </Popup>
          </Marker>
        </MapContainer>
      )
    }
    return null
  }
}
