import React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import { useI18next } from "gatsby-plugin-react-i18next"
import Map from "../components/Map"
import Linkify from "react-linkify"
import * as styles from "../styles/eventdetails.module.css"

export default function EventDetails({ data, pageContext }) {
  const { routed } = pageContext.i18n //boolean	if false it means that the page is in default language
  const isDefaultLanguage = !routed
  const { language: currentLanguage } = pageContext.i18n
  const { event } = data.gcms
  const { language } = useI18next()
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  }

  const eventDate = new Date(event.date)
  const componentDecorator = (href, text, key) => (
    <a href={href} key={key} target="_blank" rel="noopener noreferrer">
      {text}
    </a>
  )

  //Only show blog posts with correct language
  if (!isDefaultLanguage) {
    if (event.localizations.length > 0) {
      event.localizations.forEach(localization => {
        if (localization.locale === currentLanguage) {
          //Replace fields with localised fields
          event.excerpt = localization.excerpt
          event.slug = localization.slug
          event.title = localization.title
          event.description = localization.description
        }
      })
    }
  }

  return (
    <Layout subTitle={event.title}>
      <section className="section">
        <h2 className="title is-2">{event.title}</h2>
        <div>
          <img
            src={event.coverImage.url}
            alt={event.title}
            width="100%"
            height="300"
          />
        </div>
        <hr />
        <div className="block">
          <div className="media">
            <div className="media-content">
              <span className="bi bi-geo-alt has-text-danger pr-2"></span>
              <span className={styles.link}>
                <strong>
                  <Linkify componentDecorator={componentDecorator}>
                    {event.address}
                  </Linkify>
                </strong>
              </span>
              <div>
                <time dateTime={eventDate.toISOString()} className="is-italic">
                  <p>
                    <span className="bi bi-calendar4-event has-text-link pr-2"></span>
                    {eventDate.toLocaleDateString(language, options)}
                  </p>
                  <p>
                    <span className="bi bi-clock has-text-link pr-2"></span>
                    {eventDate.toLocaleTimeString(language, {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </p>
                </time>
                <time
                  dateTime={eventDate.toISOString()}
                  className="is-italic"
                ></time>
              </div>

              <hr />
            </div>
          </div>
          <div className="block is-italic">
            <p>
              <span className="bi bi-ticket-perforated has-text-link pr-2"></span>
              Adults: €{event.adultTicketPrice}
            </p>

            <p>
              <span className="bi bi-ticket-perforated has-text-link pr-2"></span>
              Concessions: €{event.concessionTicketPrice}
            </p>
          </div>
        </div>
        <hr />
        <div className="block">
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: event.description.html }}
          />
        </div>
        {event.location ? (
          <div id="map" className="block">
            <Map
              options={{
                latitude: event.location.latitude,
                longitude: event.location.longitude,
                popup: { title: event.title, address: event.address },
              }}
            ></Map>
          </div>
        ) : null}
      </section>
    </Layout>
  )
}

export const query = graphql`
  query EventDetails($id: ID, $language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    gcms {
      event(where: { id: $id }) {
        address
        adultTicketPrice
        concessionTicketPrice
        coverImage {
          url
        }
        date
        description {
          html
        }
        excerpt
        id
        locale
        slug
        title
        localizations {
          slug
          title
          excerpt
          locale
          description {
            html
          }
        }
        location {
          latitude
          longitude
        }
      }
    }
  }
`
